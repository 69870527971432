var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: !0
});
var f, g, h, k, l;

if ("undefined" === typeof window || "function" !== typeof MessageChannel) {
  var p = null,
      q = null,
      t = function () {
    if (null !== p) try {
      var a = exports.unstable_now();
      p(!0, a);
      p = null;
    } catch (b) {
      throw setTimeout(t, 0), b;
    }
  },
      u = Date.now();

  exports.unstable_now = function () {
    return Date.now() - u;
  };

  f = function (a) {
    null !== p ? setTimeout(f, 0, a) : (p = a, setTimeout(t, 0));
  };

  g = function (a, b) {
    q = setTimeout(a, b);
  };

  h = function () {
    clearTimeout(q);
  };

  k = function () {
    return !1;
  };

  l = exports.unstable_forceFrameRate = function () {};
} else {
  var w = window.performance,
      x = window.Date,
      y = window.setTimeout,
      z = window.clearTimeout,
      A = window.requestAnimationFrame,
      B = window.cancelAnimationFrame;
  "undefined" !== typeof console && ("function" !== typeof A && console.error("This browser doesn't support requestAnimationFrame. Make sure that you load a polyfill in older browsers. https://fb.me/react-polyfills"), "function" !== typeof B && console.error("This browser doesn't support cancelAnimationFrame. Make sure that you load a polyfill in older browsers. https://fb.me/react-polyfills"));
  if ("object" === typeof w && "function" === typeof w.now) exports.unstable_now = function () {
    return w.now();
  };else {
    var C = x.now();

    exports.unstable_now = function () {
      return x.now() - C;
    };
  }
  var D = !1,
      E = null,
      F = -1,
      G = 5,
      H = 0;

  k = function () {
    return exports.unstable_now() >= H;
  };

  l = function () {};

  exports.unstable_forceFrameRate = function (a) {
    0 > a || 125 < a ? console.error("forceFrameRate takes a positive int between 0 and 125, forcing framerates higher than 125 fps is not unsupported") : G = 0 < a ? Math.floor(1000 / a) : 33.33;
  };

  var I = new MessageChannel(),
      J = I.port2;

  I.port1.onmessage = function () {
    if (null !== E) {
      var a = exports.unstable_now();
      H = a + G;

      try {
        E(!0, a) ? J.postMessage(null) : (D = !1, E = null);
      } catch (b) {
        throw J.postMessage(null), b;
      }
    } else D = !1;
  };

  f = function (a) {
    E = a;
    D || (D = !0, J.postMessage(null));
  };

  g = function (a, b) {
    F = y(function () {
      a(exports.unstable_now());
    }, b);
  };

  h = function () {
    z(F);
    F = -1;
  };
}

function K(a, b) {
  var c = a.length;
  a.push(b);

  a: for (;;) {
    var d = Math.floor((c - 1) / 2),
        e = a[d];
    if (void 0 !== e && 0 < L(e, b)) a[d] = b, a[c] = e, c = d;else break a;
  }
}

function M(a) {
  a = a[0];
  return void 0 === a ? null : a;
}

function N(a) {
  var b = a[0];

  if (void 0 !== b) {
    var c = a.pop();

    if (c !== b) {
      a[0] = c;

      a: for (var d = 0, e = a.length; d < e;) {
        var m = 2 * (d + 1) - 1,
            n = a[m],
            v = m + 1,
            r = a[v];
        if (void 0 !== n && 0 > L(n, c)) void 0 !== r && 0 > L(r, n) ? (a[d] = r, a[v] = c, d = v) : (a[d] = n, a[m] = c, d = m);else if (void 0 !== r && 0 > L(r, c)) a[d] = r, a[v] = c, d = v;else break a;
      }
    }

    return b;
  }

  return null;
}

function L(a, b) {
  var c = a.sortIndex - b.sortIndex;
  return 0 !== c ? c : a.id - b.id;
}

var O = [],
    P = [],
    Q = 1,
    R = null,
    S = 3,
    T = !1,
    U = !1,
    V = !1;

function W(a) {
  for (var b = M(P); null !== b;) {
    if (null === b.callback) N(P);else if (b.startTime <= a) N(P), b.sortIndex = b.expirationTime, K(O, b);else break;
    b = M(P);
  }
}

function X(a) {
  V = !1;
  W(a);
  if (!U) if (null !== M(O)) U = !0, f(Y);else {
    var b = M(P);
    null !== b && g(X, b.startTime - a);
  }
}

function Y(a, b) {
  U = !1;
  V && (V = !1, h());
  T = !0;
  var c = S;

  try {
    W(b);

    for (R = M(O); null !== R && (!(R.expirationTime > b) || a && !k());) {
      var d = R.callback;

      if (null !== d) {
        R.callback = null;
        S = R.priorityLevel;
        var e = d(R.expirationTime <= b);
        b = exports.unstable_now();
        "function" === typeof e ? R.callback = e : R === M(O) && N(O);
        W(b);
      } else N(O);

      R = M(O);
    }

    if (null !== R) var m = !0;else {
      var n = M(P);
      null !== n && g(X, n.startTime - b);
      m = !1;
    }
    return m;
  } finally {
    R = null, S = c, T = !1;
  }
}

function Z(a) {
  switch (a) {
    case 1:
      return -1;

    case 2:
      return 250;

    case 5:
      return 1073741823;

    case 4:
      return 10000;

    default:
      return 5000;
  }
}

var aa = l;
exports.unstable_ImmediatePriority = 1;
exports.unstable_UserBlockingPriority = 2;
exports.unstable_NormalPriority = 3;
exports.unstable_IdlePriority = 5;
exports.unstable_LowPriority = 4;

exports.unstable_runWithPriority = function (a, b) {
  switch (a) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      break;

    default:
      a = 3;
  }

  var c = S;
  S = a;

  try {
    return b();
  } finally {
    S = c;
  }
};

exports.unstable_next = function (a) {
  switch (S) {
    case 1:
    case 2:
    case 3:
      var b = 3;
      break;

    default:
      b = S;
  }

  var c = S;
  S = b;

  try {
    return a();
  } finally {
    S = c;
  }
};

exports.unstable_scheduleCallback = function (a, b, c) {
  var d = exports.unstable_now();

  if ("object" === typeof c && null !== c) {
    var e = c.delay;
    e = "number" === typeof e && 0 < e ? d + e : d;
    c = "number" === typeof c.timeout ? c.timeout : Z(a);
  } else c = Z(a), e = d;

  c = e + c;
  a = {
    id: Q++,
    callback: b,
    priorityLevel: a,
    startTime: e,
    expirationTime: c,
    sortIndex: -1
  };
  e > d ? (a.sortIndex = e, K(P, a), null === M(O) && a === M(P) && (V ? h() : V = !0, g(X, e - d))) : (a.sortIndex = c, K(O, a), U || T || (U = !0, f(Y)));
  return a;
};

exports.unstable_cancelCallback = function (a) {
  a.callback = null;
};

exports.unstable_wrapCallback = function (a) {
  var b = S;
  return function () {
    var c = S;
    S = b;

    try {
      return a.apply(this, arguments);
    } finally {
      S = c;
    }
  };
};

exports.unstable_getCurrentPriorityLevel = function () {
  return S;
};

exports.unstable_shouldYield = function () {
  var a = exports.unstable_now();
  W(a);
  var b = M(O);
  return b !== R && null !== R && null !== b && null !== b.callback && b.startTime <= a && b.expirationTime < R.expirationTime || k();
};

exports.unstable_requestPaint = aa;

exports.unstable_continueExecution = function () {
  U || T || (U = !0, f(Y));
};

exports.unstable_pauseExecution = function () {};

exports.unstable_getFirstCallbackNode = function () {
  return M(O);
};

exports.unstable_Profiling = null;
export default exports;