import _schedulerProductionMin from "./cjs/scheduler.production.min.js";
export { _schedulerProductionMin as default };
export const __esModule = _schedulerProductionMin.__esModule,
      unstable_now = _schedulerProductionMin.unstable_now,
      unstable_forceFrameRate = _schedulerProductionMin.unstable_forceFrameRate,
      unstable_ImmediatePriority = _schedulerProductionMin.unstable_ImmediatePriority,
      unstable_UserBlockingPriority = _schedulerProductionMin.unstable_UserBlockingPriority,
      unstable_NormalPriority = _schedulerProductionMin.unstable_NormalPriority,
      unstable_IdlePriority = _schedulerProductionMin.unstable_IdlePriority,
      unstable_LowPriority = _schedulerProductionMin.unstable_LowPriority,
      unstable_runWithPriority = _schedulerProductionMin.unstable_runWithPriority,
      unstable_next = _schedulerProductionMin.unstable_next,
      unstable_scheduleCallback = _schedulerProductionMin.unstable_scheduleCallback,
      unstable_cancelCallback = _schedulerProductionMin.unstable_cancelCallback,
      unstable_wrapCallback = _schedulerProductionMin.unstable_wrapCallback,
      unstable_getCurrentPriorityLevel = _schedulerProductionMin.unstable_getCurrentPriorityLevel,
      unstable_shouldYield = _schedulerProductionMin.unstable_shouldYield,
      unstable_requestPaint = _schedulerProductionMin.unstable_requestPaint,
      unstable_continueExecution = _schedulerProductionMin.unstable_continueExecution,
      unstable_pauseExecution = _schedulerProductionMin.unstable_pauseExecution,
      unstable_getFirstCallbackNode = _schedulerProductionMin.unstable_getFirstCallbackNode,
      unstable_Profiling = _schedulerProductionMin.unstable_Profiling;